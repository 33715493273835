import React, { Component } from 'react';

class Content extends Component {
    render() {
        return (
            <div className='container'>
                <h1 style={{userSelect:'none'}} className='name' aria-label='My name is Sumesh'>Sumesh M Nair</h1>
                <h2 style={{userSelect:'none'}} className='title' aria-label='I am a Big Data and Cloud Engineer'>Big Data and Cloud Engineer</h2>
            </div>
        )
    }
}

export default Content;
